<template>
  <div>
    <el-form :model="form"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="12"
                style="padding-top:10px">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.begin_time"
                            style="width:100%"
                            type="datetime"
                            @change="search"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="开始时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="12"
                style="padding-top:10px">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            style="width:100%"
                            type="datetime"
                            @change="search"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="截止时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                style="padding-top:10px"
                :sm="12"
                :xl="6"
                :xs="12">
          <el-form-item label="题目ID">
            <el-input clearable
                      style="width:100%"
                      placeholder="题目ID"
                      @keyup.enter.native="search"
                      v-model="form.question_id" />
          </el-form-item>
        </el-col>

        <el-col :lg="6"
                :md="12"
                :sm="12"
                style="padding-top:10px"
                :xl="6"
                :xs="12">
          <el-form-item label="题目编码">
            <el-input clearable
                      style="width:100%"
                      @keyup.enter.native="search"
                      placeholder="题目编码"
                      v-model="form.question_code" />
          </el-form-item>
        </el-col>
        <el-col :lg="12"
                :md="24"
                style="padding-top:10px"
                :sm="24"
                :xl="12"
                :xs="12">
          <el-form-item label-width="20px">
            <el-button type="primary"
                       @click="search">搜索</el-button>
            <el-button type="primary"
                       @click="toAdd">新增</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <InfoForm ref="InfoFormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import InfoForm from '@/components/checkInfo/index.vue'

export default {
  data () {
    return {
      form: {
        question_id: '',
        question_code: '',
      },
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      showList: [],
      pageName: 'add_topic_admin',
      tableColumns: [
        { prop: 'question_id', align: 'center', label: '题目ID', width: '', showToopic: false },
        { prop: 'question_code', align: 'center', label: '题目编码号', width: '', showToopic: true },
        { prop: 'question_type_name', align: 'center', label: '题目类型名称', width: '', showToopic: false },
        { prop: 'question_category_name', align: 'center', label: '题目类别名称', width: '', showToopic: false },
        { prop: 'source_name', align: 'center', label: '题目来源名称', width: '', showToopic: false },
        { prop: 'question_code_head', align: 'center', label: '题头编码号', width: '', showToopic: false },
        { prop: 'question_serial_head', align: 'center', label: '题目在题头中的序号', width: '', showToopic: false },
        { prop: 'question_score', align: 'center', label: '题目分数', width: '', showToopic: false },
        { prop: 'question_difficulty', align: 'center', label: '题目难度', width: '', showToopic: false },
        { prop: 'question_year', align: 'center', label: '题目年份', width: '', showToopic: false },
        { prop: 'input_time', align: 'center', label: '录入时间', width: '', showToopic: false },
        { prop: 'last_edit_time', align: 'center', label: '最后修改时间', width: '', showToopic: false },
        { prop: 'question_enable', align: 'center', label: '可用', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'edit' },
            { content: '查看', type: 'look', event: 'checkInfo' },
          ]
        },
      ],

    }
  },
  components: {
    InfoForm
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'question_enable') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'question_enable') {
        return value ? '可用' : '不可用'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/question/lst',
        method: 'GET',
        params: this.form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    toAdd () {
      this.$router.push('/examinationEntry/examinationEntry/add')
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/question/del',
          method: 'post',
          data: {
            id: row.question_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    checkInfo (row) {
      this.$router.push('/examinationEntry/examinationEntry?id=' + row.question_id)
      this.$refs.InfoFormRef.dialogVisible = true
    },
    edit (row) {
      this.$router.push('/examinationEntry/examinationEntry/edit?id=' + row.question_id)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: none;
}
</style>